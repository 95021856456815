import E from 'wangeditor' // npm 安装
const { $, BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip } = E
var _this = null
export default class AudioMenu extends PanelMenu {
  constructor(editor) {
    // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
    _this = editor
    const $elem = E.$(
      `<div class="w-e-menu" data-title="音频">
                <div>音频</div>
            </div>`
    )
    super($elem, editor)

    // this.bindTooltipEvent(editor)
    this.bindEventKeyboardEvent(editor)
  }

  bindEventKeyboardEvent(editor) {
    if (!(/Firefox\/\d+/.test(navigator.userAgent) && !/Seamonkey\/\d+/.test(navigator.userAgent) ? true : false)) return
    const { txt, selection } = editor
    const { keydownEvents } = txt.eventHooks

    keydownEvents.push(function (e) {
      // 实时保存选区
      // editor.selection.saveRange()
      const $selectionContainerElem = selection.getSelectionContainerElem()
      if ($selectionContainerElem) {
        const $topElem = $selectionContainerElem.getNodeTop(editor)
        const $preElem = $topElem.length
          ? $topElem.prev().length
            ? $topElem.prev()
            : null
          : null
        if ($preElem && $preElem.attr('data-we-video-p')) {
          // 光标处于选区开头
          if (selection.getCursorPos() === 0) {
            // 如果上一个dom是包含video， 按下删除连video一块删除
            if (e.keyCode === 8) {
              $preElem.remove()
            }
          }
        }
      }
    })
  }

  // 设置布局方式
  setAlignment($node, value) {
    // 设置顶级元素匹配
    const NODENAME = ['P']
    // 获取匹配得顶级元素
    const topNode = this.getSelectedTopNode($node, NODENAME)
    // 判断是否存在
    if (topNode) {
      $(topNode).css('text-align', value)
    }
  }

  /**
  * 获取选中的元素的顶级元素
  * @params el 选中的元素
  * @params tag 匹配顶级的元素 如 P LI ....
  */
  getSelectedTopNode(el, tag) {
    let parentNode = el.elems[0] || null
    // 可能出现嵌套的情况，所以一级一级向上找，找到指定得顶级元素
    while (parentNode != null) {
      if (tag.includes(parentNode?.nodeName)) {
        return parentNode
      }
      // 兜底 body
      if (parentNode?.parentNode?.nodeName === 'BODY') {
        return null
      }
      parentNode = parentNode.parentNode
    }
    return parentNode
  }

  createShowHideFn(editor) {
    let tooltip = null
    const t = (text, prefix='') => {
      return editor.i18next.t(prefix + text)
    }
    let that = this
    /**
     * 显示 tooltip
     * @param $node 链接元素
     */
    function showAudioTooltip($node) {
      const conf = [
        {
          $elem: $("<span class='w-e-icon-trash-o'></span>"),
          onClick: (editor, $node) => {
            // 选中video元素 删除
            $node.remove()
            // 返回 true，表示执行完之后，隐藏 tooltip。否则不隐藏。
            return true
          },
        },
        {
          $elem: $('<span>100%</span>'),
          onClick: (edito, $node) => {
            $node.attr('width', '100%')
            $node.removeAttr('height')
            // 返回 true，表示执行完之后，隐藏 tooltip。否则不隐藏。
            return true
          },
        },
        {
          $elem: $('<span>50%</span>'),
          onClick: (editor, $node) => {
            $node.attr('width', '50%')
            $node.removeAttr('height')
            // 返回 true，表示执行完之后，隐藏 tooltip。否则不隐藏。
            return true
          },
        },
        {
          $elem: $('<span>30%</span>'),
          onClick: (editor, $node) => {
            $node.attr('width', '30%')
            $node.removeAttr('height')
            // 返回 true，表示执行完之后，隐藏 tooltip。否则不隐藏。
            return true
          },
        },
        {
          $elem: $(`<span>${t('重置')}</span>`),
          onClick: (editor, $node) => {
            $node.removeAttr('width')
            $node.removeAttr('height')

            // 返回 true，表示执行完之后，隐藏 tooltip。否则不隐藏。
            return true
          },
        },
        {
          $elem: $(`<span>${t('menus.justify.靠左')}</span>`),
          onClick: (editor, $node) => {
            // 获取顶级元素
            that.setAlignment($node, 'left')
            // 返回 true，表示执行完之后，隐藏 tooltip。否则不隐藏。
            return true
          },
        },
        {
          $elem: $(`<span>${t('menus.justify.居中')}</span>`),
          onClick: (editor, $node) => {
            // 获取顶级元素
            that.setAlignment($node, 'center')
            // 返回 true，表示执行完之后，隐藏 tooltip。否则不隐藏。
            return true
          },
        },
        {
          $elem: $(`<span>${t('menus.justify.靠右')}</span>`),
          onClick: (editor, $node) => {
            // 获取顶级元素
            that.setAlignment($node, 'right')
            // 返回 true，表示执行完之后，隐藏 tooltip。否则不隐藏。
            return true
          },
        },
      ]

      tooltip = new Tooltip(editor, $node, conf)
      tooltip.create()
    }

    /**
     * 隐藏 tooltip
     */
    function hideAudioTooltip() {
      // 移除 tooltip
      if (tooltip) {
        tooltip.remove()
        tooltip = null
      }
    }

    return {
      showAudioTooltip,
      hideAudioTooltip,
    }
  }

  bindTooltipEvent(editor) {
    console.log(editor)
    const { showAudioTooltip, hideAudioTooltip } = this.createShowHideFn(editor)

    // 点击音频元素是，显示 tooltip
    editor.txt.eventHooks.audioClickEvents = new Array()
    editor.txt.eventHooks.audioClickEvents.push(showAudioTooltip)

    // 点击其他地方，或者滚动时，隐藏 tooltip
    editor.txt.eventHooks.clickEvents.push(hideAudioTooltip)
    editor.txt.eventHooks.keyupEvents.push(hideAudioTooltip)
    editor.txt.eventHooks.toolbarClickEvents.push(hideAudioTooltip)
    editor.txt.eventHooks.menuClickEvents.push(hideAudioTooltip)
    editor.txt.eventHooks.textScrollEvents.push(hideAudioTooltip)

    // change 时隐藏
    editor.txt.eventHooks.changeEvents.push(hideAudioTooltip)

    console.log(editor.txt.eventHooks)
  }


  // 菜单点击事件
  clickHandler() {
    // 做任何你想做的事情
    // 可参考【常用 API】文档，来操作编辑器
    this.createPanel('')
  }

  insertAudio(audio) {
    _this.cmd.do('insertHTML', `<audio controls src=${audio}></audio><p data-we-empty-p><br></p>`)
    _this.config.onlineAudioCallback && _this.config.onlineAudioCallback(audio)
  }

  checkOnlineAudio (audio) {
    let check = _this.config.onlineAudioCheck ? _this.config.onlineAudioCheck(audio) : true
    if (check === true) {
      return true
    }
    if (typeof check === 'string') {
      //用户未能通过开发者的校验，开发者希望我们提示这一字符串
      _this.config.customAlert(check, 'error')
    }
    return false
  }

  uploadAudio (files) {
    console.log(files);
    if (!files.length) {
      return
    }
    const t = (text) => {
      return _this.i18next.t('validate.' + text)
    }

    // ------------------------------ 获取配置信息 ------------------------------
    // 服务端地址
    let uploadAudioServer = _this.config.uploadAudioServer
    // 上传音频的最大体积，默认 1024M
    const maxSize = _this.config.uploadAudioMaxSize
    const uploadAudioMaxSize = maxSize / 1024
    // 一次最多上传多少个音频
    // const uploadAudioMaxLength = config.uploadAudioMaxLength
    // 自定义上传音频的名称
    const uploadAudioName = _this.config.uploadAudioName
    // 上传音频自定义参数
    const uploadAudioParams = _this.config.uploadAudioParams
    // 自定义参数拼接到 url 中
    const uploadAudioParamsWithUrl = _this.config.uploadAudioParamsWithUrl
    // 上传音频自定义 header
    const uploadAudioHeaders = _this.config.uploadAudioHeaders
    // 钩子函数
    const uploadAudioHooks = _this.config.uploadAudioHooks
    // 上传音频超时时间 ms 默认2个小时
    const uploadAudioTimeout = _this.config.uploadAudioTimeout
    // 跨域带 cookie
    const withAudioCredentials = _this.config.withAudioCredentials
    // 自定义上传
    const customUploadAudio = _this.config.customUploadAudio
    // 格式校验
    const uploadAudioAccept = _this.config.uploadAudioAccept

    const resultFiles = []
    const errInfos = []
    let file = files[0]
    // files.map((file) => {
    const name = file.name.toLowerCase();
    const size = file.size / 1024 / 1024;

      if (!name || !size) {
        return
      }

      if (!(uploadAudioAccept instanceof Array)) {
        // 格式不是数组
        errInfos.push(`【${uploadAudioAccept}】${t('uploadAudioAccept 不是Array')}`)
        return
      }

      if (!uploadAudioAccept.some(item => item === name.split('.')[name.split('.').length - 1])) {
        // 后缀名不合法，不是音频
        errInfos.push(`【${name}】${t('不是音频')}`)
        return
      }

      if (uploadAudioMaxSize < size) {
        // 上传视频过大
        errInfos.push(`【${name}】${t('大于')} ${uploadAudioMaxSize}M`)
        return
      }
      //验证通过的加入结果列表
      resultFiles.push(file)
    // })

    // 抛出验证信息
    if (errInfos.length) {
      _this.config.customAlert(`${t('音频验证未通过')}: \n` + errInfos.join('\n'), 'warning')
      return
    }
    // 如果过滤后文件列表为空直接返回
    if (resultFiles.length === 0) {
      _this.config.customAlert(t('传入的文件不合法'), 'warning')
      return
    }

    // ------------------------------ 自定义上传 ------------------------------
    if (customUploadAudio && typeof customUploadAudio === 'function') {
      customUploadAudio(resultFiles, this.insertUploadAudio.bind(this))
      return
    }

    // 添加音频数据
    const formData = new FormData()
    resultFiles.forEach((file, index) => {
      let name = uploadAudioName || file.name
      if (resultFiles.length > 1) {
        // 多个文件时，filename 不能重复
        name = name + (index + 1)
      }
      formData.append(name, file)
    })

    // ------------------------------ 上传视频 ------------------------------

    //添加自定义参数  基于有服务端地址的情况下
    if (uploadAudioServer) {
      // 添加自定义参数
      const uploadAudioServerArr = uploadAudioServer.split('#')
      uploadAudioServer = uploadAudioServerArr[0]
      const uploadAudioServerHash = uploadAudioServerArr[1] || ''
      uploadAudioParams.forEach((key, val) => {
        // 因使用者反应，自定义参数不能默认 encode ，由 v3.1.1 版本开始注释掉
        // val = encodeURIComponent(val)

        // 第一，将参数拼接到 url 中
        if (uploadAudioParamsWithUrl) {
          if (uploadAudioServer.indexOf('?') > 0) {
            uploadAudioServer += '&'
          } else {
            uploadAudioServer += '?'
          }
          uploadAudioServer = uploadAudioServer + key + '=' + val
        }

        // 第二，将参数添加到 formData 中
        formData.append(key, val)
      })

      if (uploadAudioServerHash) {
        uploadAudioServer += '#' + uploadAudioServerHash
      }

      // 开始上传
      const xhr = new XMLHttpRequest()
      xhr.open('POST', uploadAudioServer)

      const xhrOption = {
        timeout: uploadAudioTimeout,
        formData,
        headers: uploadAudioHeaders,
        withCredentials: !!withAudioCredentials,
        beforeSend: xhr => {
          if (uploadAudioHooks.before)
            return uploadAudioHooks.before(xhr, editor, resultFiles)
        },
        onTimeout: xhr => {
          _this.config.customAlert(t('上传视频超时'), 'error')
          if (uploadAudioHooks.timeout) uploadAudioHooks.timeout(xhr, editor)
        },
        onProgress: (percent, e) => {
          const progressBar = new Progress(_this)
          if (e.lengthComputable) {
            percent = e.loaded / e.total
            progressBar.show(percent)
          }
        },
        onError: xhr => {
          _this.config.customAlert(
            t('上传音频错误'),
            'error',
            `${t('上传音频错误')}，${t('服务器返回状态')}: ${xhr.status}`
          )
          if (uploadAudioHooks.error) uploadAudioHooks.error(xhr, editor)
        },
        onFail: (xhr, resultStr) => {
          _this.config.customAlert(
            t('上传音频失败'),
            'error',
            t('上传音频返回结果错误') + `，${t('返回结果')}: ` + resultStr
          )
          if (uploadAudioHooks.fail) uploadAudioHooks.fail(xhr, editor, resultStr)
        },
        onSuccess: (xhr, result = { errno: null, data: { url: "" } }) => {
          if (uploadAudioHooks.customInsert) {
            // 自定义插入视频
            uploadAudioHooks.customInsert(this.insertUploadAudio.bind(this), result, _this)
            return
          }
          if (result.errno != '0') {
            // 返回格式不对，应该为 { errno: 0, data: [...] }
            _this.config.customAlert(
              t('上传音频失败'),
              'error',
              `${t('上传音频返回结果错误')}，${t('返回结果')} errno=${result.errno}`
            )
            if (uploadAudioHooks.fail) uploadAudioHooks.fail(xhr, _this, result)
            return
          }

          // 成功，插入视频
          const data = result.data

          this.insertUploadAudio(data.url)

          // 钩子函数
          if (uploadAudioHooks.success) uploadAudioHooks.success(xhr, _this, result)
        },
      }

      // 超时，默认 10s
      xhr.timeout = xhrOption.timeout || 10 * 1000
      xhr.ontimeout = () => {
        console.error('wangEditor - 请求超时')
        xhrOption.onTimeout && xhrOption.onTimeout(xhr)
      }

      // 进度
      if (xhr.upload) {
        xhr.upload.onprogress = (e) => {
          const percent = e.loaded / e.total
          xhrOption.onProgress && xhrOption.onProgress(percent, e)
        }
      }

      // 自定义 header
      if (xhrOption.headers) {
        xhrOption.headers.forEach((key, val) => {
          xhr.setRequestHeader(key, val)
        })
      }

      // 跨域传 cookie
      xhr.withCredentials = !!xhrOption.withCredentials

      // 上传之前的钩子函数，在 xhr.send() 之前执行
      if (xhrOption.beforeSend) {
        const beforeResult = xhrOption.beforeSend(xhr)
        if (beforeResult && typeof beforeResult === 'object') {
          if (beforeResult.prevent) {
            // 如果返回的结果是 {prevent: true, msg: 'xxxx'} 则表示用户放弃上传
            return beforeResult.msg
          }
        }
      }

      // 服务端返回之后
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) return
        const status = xhr.status
        if (status < 200) return // 请求发送过程中，尚未返回
        if (status >= 300 && status < 400) return // 重定向
        if (status >= 400) {
          // 40x 50x 报错
          console.error('wangEditor - XHR 报错，状态码 ' + status)
          if (xhrOption.onError) xhrOption.onError(xhr) // 有，则执行 onError 函数即可
          return
        }

        // status = 200 ，得到结果
        const resultStr = xhr.responseText
        let result = null
        if (typeof resultStr !== 'object') {
          try {
            result = JSON.parse(resultStr)
          } catch (ex) {
            console.error('wangEditor - 返回结果不是 JSON 格式', resultStr)
            if (xhrOption.onFail) xhrOption.onFail(xhr, resultStr)
            return
          }
        } else {
          result = resultStr
        }
        xhrOption.onSuccess(xhr, result)
      }

      // 发送请求
      xhr.send(xhrOption.formData || null)



      if (typeof result === 'string') {
        // 上传被阻止
        config.customAlert(result, 'error')
      }
    }
  }

  insertUploadAudio(url) {
    const t = (text, prefix) => {
      return _this.i18next.t('validate.' + text)
    }

    // 判断用户是否自定义插入视频
    if (!_this.config.customInsertVideo) {
      if (/Firefox\/\d+/.test(navigator.userAgent) && !/Seamonkey\/\d+/.test(navigator.userAgent) ? true : false) {
        _this.cmd.do(
          'insertHTML',
          `<p data-we-video-p="true"><audio src="${url}" controls="controls" style="max-width:100%"></audio></p><p>&#8203</p>`
        )
      } else {
        _this.cmd.do(
          'insertHTML',
          `<p data-we-empty-p><audio src="${url}" controls="controls" style="max-width:100%"></audio></p>`
        )
      }
    } else {
      _this.config.customInsertVideo(url)
      return
    }
  }

  createPanel(iframe) {
    let inputIFrameId = 'input-iframe' + Math.random().toString().slice(2)
    let btnOkId = 'btn-ok' + Math.random().toString().slice(2)
    let inputUploadId = 'input-upload' + Math.random().toString().slice(2)
    let btnStartId = 'btn-local-ok' + Math.random().toString().slice(2)
    let conf = {
      width: 300,
      height: 0,
      tabs: [
        {
          // tab 的标题
          title: _this.i18next.t('menus.panelMenus.audioMenu.上传音频'),
          tpl: `<div class="w-e-up-video-container">
                  <div id="${btnStartId}" class="w-e-up-btn">
                      <i class="w-e-icon-upload2"></i>
                  </div>
                  <div style="display:none;">
                      <input id="${inputUploadId}" type="file" accept="audio/*"/>
                  </div>
               </div>`,
          events: [
            // 触发选择视频
            {
              selector: '#' + btnStartId,
              type: 'click',
              fn: () => {
                let $file = $('#' + inputUploadId)
                let fileElem = $file.elems[0]
                if (fileElem) {
                  fileElem.click()
                } else {
                  // 返回 true 可关闭 panel
                  return true
                }
              },
            },
            // 选择视频完毕
            {
              selector: '#' + inputUploadId,
              type: 'change',
              fn: () => {
                let $file = $('#' + inputUploadId)
                let fileElem = $file.elems[0]
                if (!fileElem) {
                  // 返回 true 可关闭 panel
                  return true
                }

                // 获取选中的 file 对象列表
                let fileList = fileElem.files
                if (fileList.length) {
                  this.uploadAudio(fileList)
                }

                // 返回 true 可关闭 panel
                return true
              },
            },
          ],
        },
        {
          // tab 的标题
          title: _this.i18next.t('menus.panelMenus.audioMenu.插入音频'),
          // 模板
          tpl: `<div>
                <input
                    id="${inputIFrameId}"
                    type="text"
                    class="block"
                    placeholder="${_this.i18next.t('如')}：http://xxx.mp3"/>
                </td>
                <div class="w-e-button-container">
                    <button type="button" id="${btnOkId}" class="right">
                        ${_this.i18next.t('插入')}
                    </button>
                </div>
            </div>`,
          // 事件绑定
          events: [
            // 插入音频
            {
              selector: '#' + btnOkId,
              type: 'click',
              fn: () => {
                // 执行插入音频
                let $audio = $('#' + inputIFrameId)
                let audio = $audio.val().trim()

                // 音频为空，则不插入
                if (!audio) return
                // 对当前用户插入的内容进行判断，插入为空，或者返回false，都停止插入
                if (!this.checkOnlineAudio(audio)) return

                this.insertAudio(audio)

                // 返回 true，表示该事件执行完之后，panel 要关闭。否则 panel 不会关闭
                return true
              },
              bindEnter: true,
            },
          ],
        }
      ]
    }
    let panel = new Panel(this, conf)
    panel.create()
  }
  // 菜单是否被激活（如果不需要，这个函数可以空着）
  // 1. 激活是什么？光标放在一段加粗、下划线的文本时，菜单栏里的 B 和 U 被激活，如下图
  // 2. 什么时候执行这个函数？每次编辑器区域的选区变化（如鼠标操作、键盘操作等），都会触发各个菜单的 tryChangeActive 函数，重新计算菜单的激活状态
  tryChangeActive() {
    // 激活菜单
    // 1. 菜单 DOM 节点会增加一个 .w-e-active 的 css class
    // 2. this.this.isActive === true
    // this.active()

    // // 取消激活菜单
    // // 1. 菜单 DOM 节点会删掉 .w-e-active
    // // 2. this.this.isActive === false
    // this.unActive()
  }
}
