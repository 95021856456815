<template>
  <div :id="id"></div><!--v-loading.fullscreen.lock="uploadingFlag"-->
</template>
<script>
import E from 'wangeditor/dist/wangEditor.js'
import { getToken } from "@/utils/auth";
import AudioMenu from '@/utils/audioMenu.js'
import Video from '@/utils/video.js'
export default {
  name: "wangEditorComponent",
  props: {
    id: {
      type: String,
      default: "wangEditor"
    },
    height: {
      type: String,
      default: 'auto'
    },
    defaultContent: {
      type: String,
      default: ''
    }
  },
  data(){
    return{
      editor: null, //编辑器对象
      uploadImgUrl: (process.env.NODE_ENV == "development" ? process.env.VUE_APP_LOCAL_URL + "/webForm" : process.env.VUE_APP_LOCAL_URL) + "/api/upload/uploadImgToOSS", //上传图片地址
      uploadVideoUrl: (process.env.NODE_ENV == "development" ? process.env.VUE_APP_LOCAL_URL + "/webForm" : process.env.VUE_APP_LOCAL_URL) + "/api/upload/uploadVideoToOSS", //上传视频地址
      // uploadHeader: { token: this.getToken() }, //上传headers
      fileTypeList: ["png", "jpg", "jpeg", "gif"], //文件类型list
      videoTypeList: ['mp4'], //视频文件类型list
      audioTypeList: ['mp3'], //音频文件类型list
      uploadingFlag: null, //上传loading
      setTimeOutObject: null, //定时对象
    }
  },
  watch: {
    defaultContent(val){
      if(val){
        if(this.editor){
          this.editor.destroy()
          this.editor = null
        }
        this.initData() //初始化编辑器
      }
    }
  },
  mounted(){
    this.initData() //初始化编辑器
  },
  methods: {
    getToken,
    initData(){
      let that = this

      this.editor = new E('#'+ this.id)

      console.log(this.editor)

      this.editor.menus.extend('audioMenu', AudioMenu)  // 配置扩展的菜单

      this.editor.menus.extend('video', Video)  // 配置扩展的菜单

      this.editor.config.menus = [ 'head', 'bold', 'fontSize', 'fontName', 'italic', 'underline', 'strikeThrough', 'indent', 'lineHeight', 'foreColor', 'backColor', 'link', 'list', 'justify', 'image', 'video', 'audioMenu', 'table', 'code', 'splitLine', 'undo', 'redo', ]

      this.editor.config.height = this.height !== 'auto' ? Number(this.height) : this.height

      //自定义图片上传
      this.editor.config.uploadImgServer = this.uploadImgUrl

      this.editor.config.uploadImgMaxSize = 10 * 1024 * 1024

      this.editor.config.uploadImgMaxLength = 1

      this.editor.config.uploadImgAccept = this.fileTypeList

      this.editor.config.showLinkImg = false

      this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        console.log(resultFiles)
        let files = resultFiles
        let errorList = []
        let requestList = []
        const fileName = files[0].name.toLowerCase();
        let isJPG =  that.fileTypeList.indexOf(fileName.substr(fileName.lastIndexOf(".")+1,fileName.length)) != -1;
        if(resultFiles.length > 1){
          that.$message.warning("一次性最多上传1张图片")
          return;
        }
        if(files[0].size > 20 * 1024 * 1024){
          that.$message.warning(fileName+"的大小超过了20M，不可上传")
          return;
        }
        if(!isJPG){
          that.$message.warning("上传文件只能是 png, jpg, jpeg, gif 格式!");
          return;
        }
        that.uploadingFlag = that.$loading({
          lock: true,
          fullscreen: true,
          text: '上传中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        that.$api.importImgFile('/api/upload/uploadImgToOSS',files[0]).then(res => {
          if(res.data.indexOf("http") != -1){
            that.editor.cmd.do('insertHTML','<img src="'+ res.data +'" style="max-width:100%;" contenteditable="false">')
            that.uploadingFlag.close()
            // insertImgFn(res.data)
          } else {
            that.uploadingFlag.close()
            that.$message.error(res.data);
          }
        }).catch(() => {
          that.uploadingFlag.close()
        })

        // 上传图片，返回结果，将图片插入到编辑器中
        // insertImgFn(imgUrl)
      }

      //自定义视频插入
      this.editor.config.uploadVideoServer = this.uploadVideoUrl

      this.editor.config.uploadVideoMaxSize = 1 * 1024 * 1024 * 1024 // 1024m

      this.editor.config.uploadVideoAccept = this.videoTypeList

      this.editor.config.customUploadVideo = function (resultFiles, insertVideoFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        console.log(resultFiles)
        let files = resultFiles
        let errorList = []
        let requestList = []
        let fileName = files[0].name.toLowerCase();
        let isJPG =  that.videoTypeList.indexOf(fileName.substr(fileName.lastIndexOf(".")+1,fileName.length)) != -1;
        console.log(isJPG);
        if(resultFiles.length > 1){
          that.$message.warning("一次性最多上传1个视频")
          return;
        }
        if(files[0].size > 500 * 1024 * 1024){
          that.$message.warning(fileName+"的大小超过了500M，不可上传")
          return;
        }
        if(!isJPG){
          that.$message.warning("上传文件只能是 mp4 格式!");
          return;
        }
        that.uploadingFlag = that.$loading({
          lock: true,
          fullscreen: true,
          text: '上传中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        that.$api.importImgFile('/api/upload/uploadVideoToOSS',files[0]).then(res => {
          if(res.data.indexOf("http") != -1){
            that.editor.cmd.do('insertHTML','<p><br></p><p contenteditable="false"><video src="'+ res.data +'" controls="controls" style="max-width:100%"></video></p><p><br></p>')
            // insertVideoFn(res.data)
            that.uploadingFlag.close()
          } else {
            that.$message.error(res.data);
            that.uploadingFlag.close()
          }
        }).catch(() => {
          that.uploadingFlag.close()
        })

        // 上传图片，返回结果，将图片插入到编辑器中
        // insertImgFn(imgUrl)
      }

      //自定义音频上传
      this.editor.config.uploadAudioServer = this.uploadVideoUrl

      this.editor.config.uploadAudioMaxSize = 10 * 1024 * 1024 // 10m

      this.editor.config.uploadAudioAccept = this.audioTypeList

      this.editor.config.customUploadAudio = function (resultFiles, insertAudioFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        console.log(resultFiles)
        let files = resultFiles
        let errorList = []
        let requestList = []
        let fileName = files[0].name.toLowerCase();
        console.log(files);
        let isJPG =  that.audioTypeList.indexOf(fileName.substr(fileName.lastIndexOf(".")+1,fileName.length)) != -1;
        if(resultFiles.length > 1){
          that.$message.warning("一次性最多上传1个音频")
          return;
        }
        if(files[0].size > 10 * 1024 * 1024){
          that.$message.warning(fileName+"的大小超过了10M，不可上传")
          return;
        }
        if(!isJPG){
          that.$message.warning("上传文件只能是 mp3 格式!");
          return;
        }
        that.uploadingFlag = that.$loading({
          lock: true,
          fullscreen: true,
          text: '上传中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        that.$api.importImgFile('/api/upload/uploadAudioToOSS',files[0]).then(res => {
          if(res.data.indexOf("http") != -1){
            that.editor.cmd.do('insertHTML','<p><br></p><p contenteditable="false"><audio name="'+ files[0].name.substr(0,files[0].name.lastIndexOf(".")) +'" src="'+ res.data +'" controls></audio></p><p><br></p>')
            // insertAudioFn(res.data)
            that.uploadingFlag.close()
          } else {
            that.$message.error(res.data);
            that.uploadingFlag.close()
          }
        }).catch(() => {
          that.uploadingFlag.close()
        })

        // 上传图片，返回结果，将图片插入到编辑器中
        // insertImgFn(imgUrl)
      }

      //粘贴忽略图片
      this.editor.config.pasteIgnoreImg = true

      //自动聚焦
      this.editor.config.focus = true


      this.editor.config.onchange = function (html) {
        // 第二步，监控变化，同步更新到 textarea
        console.log(html)
        if(html){
          that.$emit("getContent", { content: html })
        } else {
          that.editor.txt.html('<p><br/></p>')
        }
      }

      this.editor.create()
      console.log(this.editor.config)
      this.$nextTick(() => {
        if(this.defaultContent){
          this.editor.txt.html(this.defaultContent)
        }
      })
    }
  },
  beforeDestroy() {
    // 销毁编辑器
    this.editor.destroy()
    this.editor = null
  }
}
</script>
